<template>
  <div class="list-wrapper">
    <div class="list-banner">
      <img class="_pic" :src="categoryInfo.category_img" alt="">
      <img class="_back" @click="$router.go(-1)" src="https://pic.eaworks.cn/chenghua/btn_back.png" alt="">
    </div>
    <div class="_list">
      <div class="_item" v-for="item of shopList" @click="onClickItem(item)">
        <van-row>
          <van-col span="5">
<!--            <div class="pic_box">-->
              <img class="_pic" :src="item.merchant_logo" alt=""/>
<!--            </div>-->
          </van-col>
          <van-col span="19">
            <div class="_right">
              <div class="_title">{{ item.merchant_name }}</div>
              <div class="_link">
                <img src="https://pic.eaworks.cn/chenghua/btn_link.png" alt="">
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      shopList: [],
      categoryInfo: {}
    };
  },

  created() {
    console.log("-> [card.vue] created");
    if (this.$route.query.type) {
      this.ajaxGetCategoryDetail(this.$route.query.type);
    }
  },

  mounted() {
    console.log("-> [card.vue] mounted");
  },
  methods: {
    onClickItem({id}) {
      this.$router.push("/shop/detail?id=" + id)
    },

    async ajaxGetCategoryDetail(type) {
      // 获取分类详情
      let {data, code} = await this.$ajax.gateway("/api/api_get_category_by_id", {
        "METHOD": "get",
        "id": type
      });
      if (code === 200) {
        this.categoryInfo = data;
        this.ajaxGetListData(data.id);
      }

    },

    async ajaxGetListData(type) {
      let wxCode = localStorage.getItem("_wx_code");
      console.log("::-> [wxCode]", wxCode);

      // 获取指定分类商户列表
      let {data} = await this.$ajax.gateway("/api/api_get_merchant_by_category_id", {
        "METHOD": "get",
        "category_id": type
      });
      console.log("::-> [data]", data);
      this.shopList = data;
    },
  }
};
</script>

<style lang="less" scoped>
.list-wrapper {
  //background: linear-gradient(to top,  #fdffeb, #d5f597);
  background: #fdffeb;

  .list-banner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    img {
      display: block;
      width: 100%;
    }

    ._back {
      width: 1.8rem;
      position: fixed;
      top: 0.5rem;
      left: 0;
      z-index: 10;
    }
  }

  ._list {
    min-height: 71vh;
    position: relative;
    display: block;
    padding: 5rem 1.1rem 0.1rem;

    ._item {
      width: 100%;
      background: linear-gradient(to left, #d5f597, #80a44b);
      border: 0.04rem solid #305a43;
      border-radius: 0.4rem;
      margin: 0 auto 0.4rem;
      color: #2f2e28;
      font-size: 0.4rem;
      padding: 0.2rem 0;
      font-weight: 600;
      //.pic_box {
      //  padding-top: 0.1rem;
      //  clip-path: circle(36%);
      //  box-shadow: inset -1rem -3rem #fae1b6;
      //}

      img._pic {
        width: 100%;
        margin: 0 auto;
        //clip-path: circle(32%);
      }

      ._right {
        position: relative;
        height: 1.5rem;

        ._title {
          padding-top: 0.2rem;
        }

        ._link {
          position: absolute;
          right: 0.2rem;
          bottom: 0;

          img {
            display: block;
            width: 2.2rem;
          }
        }
      }
    }
  }
}
</style>
